<template>
    <div class="add">
        <el-form
            ref="form"
            style="
                flex: 1;
                margin-top: 20px;
                margin-left: 30px;
                margin-bottom: 80px;
            "
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="企业名称" required>
                <el-input
                    style=""
                    v-model="form.companyName"
                    size="small"
                    placeholder="请输入企业名称"
                    clearable=""
                ></el-input>
            </el-form-item>
            <el-form-item label="客户" required>
                <el-select
                    remote
                    :remote-method="remoteMethod"
                    v-model="form.customerId"
                    size="small"
                    filterable
                    style="width: 270px"
                    clearable
                    placeholder="请选择或输入关键字搜索"
                >
                    <el-option
                        v-for="item in Relation"
                        :key="item.id"
                        :label="item.companyName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="域名">
                <el-input
                    v-model="form.domain"
                    size="small"
                    placeholder="请输入域名"
                    clearable=""
                ></el-input>
            </el-form-item>
            <el-form-item label="开通时间">
                <el-date-picker
                    size="small"
                    v-model="form.reportTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 270px"
                    type="date"
                    placeholder="选择日期"
                    clearable
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="到期时间"
                ><el-date-picker
                    size="small"
                    v-model="form.reportExpireTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 270px"
                    type="date"
                    placeholder="选择日期"
                    clearable
                >
                </el-date-picker>
            </el-form-item>

            <el-form-item label="域名所有者">
                <el-input
                    style=""
                    v-model="form.domainHave"
                    size="small"
                    placeholder="请输入域名所有者"
                    clearable=""
                ></el-input
            ></el-form-item>
            <el-form-item label="下单后台" required>
                <el-select
                    v-model="form.libraryCategory"
                    clearable
                    style="width: 270px"
                    size="small"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in accountListData"
                        :key="item.id"
                        :label="item.accountName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 280px;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保存</el-button
            >
        </div>
    </div>
</template>
<script>
import { closedCusAdd } from '@/api/closedCus/closedCus';
import { meCustomerList } from '@/api/user/ae/customer';
import { customerList, accountList } from '@/api/report/report.js';
export default {
    data() {
        return {
            form: {},
            Relation: [],
            createDate: [],
            value: '',
            isVerify: false,
            accountListData: [],
        };
    },

    methods: {
        accountList() {
            let data = {
                param: {
                    productType: 12,
                },
                pageNum: 0,
                pageSize: 0,
            };
            accountList(data).then((res) => {
                if (res.code == 200) {
                    this.accountListData = res.data.list;
                }
            });
        },
        remoteMethod(query) {
            this.getRelation(query);
        },
        getRelation(name) {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            let data = {
                param: {
                    customerStatusList: [1, 2, 3],
                },
                pageNum: 1,
                pageSize: 20,
            };

            if (!btn.depSearch && btn.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (btn.depSearch && !btn.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }

            if (!btn.depSearch && !btn.searchUser) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (name) {
                data.param.name = name;
            }

            customerList(data).then((res) => {
                this.Relation = res.data.list;
            });
        },

        onSubmit() {
            if (!this.form.companyName) {
                return this.$message.error('请输入企业名称');
            }
            if (!this.form.customerId) {
                return this.$message.error('请选择客户');
            }
            if (!this.form.libraryCategory) {
                return this.$message.error('请选择下单后台');
            }
            let data = {
                param: this.form,
            };
            data.param.type = 12;
            closedCusAdd(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.onClose();
                    this.form = {};
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add {
    width: 100%;
    .el-input {
        width: 270px;
    }
}
.bottom-but {
    width: 100%;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
.el-icon-warning {
    position: absolute;
    left: -120px;
    top: 13px;
    color: #d0021b;
}
.el-icon-success {
    position: absolute;
    left: -120px;
    top: 13px;
    color: #09d002;
}
</style>
